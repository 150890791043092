<template>
  <div class="transaction-profiles-form">
    <a-spin :spinning="loading" :tip="labels.form.loading">
      <ValidationObserver ref="observer">
        <a-form
          :model="form"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <a-row
            type="flex"
            justify="space-between"
            :gutter="16"
            :style="{ marginBottom: '15px' }"
          >
            <!-- Title -->
            <a-col :xs="24" :md="12">
              <h2 class="form-title">
                <a-icon :type="isEdit ? 'edit' : 'form'" class="form-icon" />
                <span>{{
                  isEdit
                    ? `${labels.form.titleView} número: ${form.code || ""}`
                    : labels.form.titleCreate
                }}</span>
              </h2>
            </a-col>
            <!-- Actions -->
            <a-col :xs="24" :md="12">
              <a-row
                type="flex"
                justify="end"
                :gutter="16"
                :style="{
                  alignItems: 'flex-end',
                  marginBottom: '0px',
                }"
              >
                <a-col>
                  <a-button-group>
                    <a-button
                      :type="isEdit ? 'primary' : 'danger'"
                      ghost
                      @click="handleCancel"
                      icon="arrow-left"
                    >
                      <span>{{ labels.form.goBack }}</span>
                    </a-button>
                    <a-button
                      v-if="isEdit && canDelete"
                      type="danger"
                      @click="confirmDelete"
                    >
                      <span>{{ labels.form.deleteTransactionProfile }}</span>
                    </a-button>
                    <a-button
                      v-if="!isMobile && canEdit"
                      type="primary"
                      ghost
                      :loading="submitting"
                      @click="validate().then(() => handleSubmit(true))"
                    >
                      <span>{{ labels.form.saveAndClose }}</span>
                    </a-button>
                    <a-button
                      v-if="canEdit"
                      type="primary"
                      :loading="submitting"
                      @click="validate().then(handleSubmit)"
                    >
                      <span>{{ labels.form.save }}</span>
                    </a-button>
                    <a-dropdown :trigger="['click']" v-if="isMobile && canEdit">
                      <a-menu slot="overlay">
                        <a-menu-item
                          key="1"
                          @click="validate().then(() => handleSubmit(true))"
                          >{{ labels.form.saveAndClose }}</a-menu-item
                        >
                      </a-menu>
                      <a-button type="primary" ghost icon="more" />
                    </a-dropdown>
                  </a-button-group>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="center">
            <a-col :span="24">
              <a-card>
                <a-row type="flex" justify="space-between">
                  <a-col :xs="20" :sm="20" :md="8">
                    <Input
                      v-model="form.name"
                      :vid="labels.form.name.label"
                      :label="labels.form.name.label"
                      :name="labels.form.name.label"
                      :formitem="{ label: labels.form.name.label }"
                      :placeholder="labels.form.name.placeholder"
                      rules="required"
                      :readOnly="!canEdit"
                      :allowClear="true"
                    />
                  </a-col>
                  <a-col>
                    <SwitchComponent
                      v-model="form.status"
                      :vid="labels.form.status.label"
                      :showLabel="true"
                      :label="labels.form.status.label"
                      :name="labels.form.status.label"
                      :formitem="{ label: labels.form.status.label }"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>
                <!-- DIVIDER -->
                <a-row type="flex" justify="start">
                  <a-col :span="24">
                    <a-divider orientation="left">
                      <a-button
                        v-if="canEdit"
                        type="link"
                        @click="openConfirmModal"
                        icon="plus"
                        :title="labels.form.type.description"
                      >
                        {{ labels.form.type.label }}
                      </a-button>
                    </a-divider>
                  </a-col>
                </a-row>

                <!-- USER DEPOSIT TRANSACTION -->
                <template v-for="(schema, index) in form.schema">
                  <div class="schema-item" :key="index">
                    <a-row
                      type="flex"
                      :justify="isMobile ? 'space-between' : 'start'"
                      :key="`title-${index}`"
                    >
                      <a-col :xs="16" :sm="16" :md="6">
                        <h3 class="type-label">
                          {{ getSchemaType(schema.type) }}
                        </h3>
                      </a-col>
                      <a-col v-bind="isMobile ? {} : { sm: 24, md: 8 }">
                        <switch-component
                          v-model="schema.status"
                          :vid="
                            isQuantityLimit(schema)
                              ? labels.form.type.transactionQuantityLimit.label
                              : labels.form.type.transactionAmountLimit.label
                          "
                          :showLabel="false"
                        />
                      </a-col>
                    </a-row>
                    <a-row
                      type="flex"
                      justify="start"
                      :gutter="[24, 24]"
                      :key="`content-${index}`"
                    >
                      <a-col :xs="24" :sm="24" :md="12">
                        <SelectPagination
                          v-model="schema.interval"
                          :data="intervals"
                          :vid="
                            isQuantityLimit(schema)
                              ? labels.form.type.transactionQuantityLimit
                                  .interval.label
                              : labels.form.type.transactionAmountLimit.interval
                                  .label
                          "
                          :label="
                            isQuantityLimit(schema)
                              ? labels.form.type.transactionQuantityLimit
                                  .interval.label
                              : labels.form.type.transactionAmountLimit.interval
                                  .label
                          "
                          :name="
                            isQuantityLimit(schema)
                              ? labels.form.type.transactionQuantityLimit
                                  .interval.label
                              : labels.form.type.transactionAmountLimit.interval
                                  .label
                          "
                          :placeholder="
                            isQuantityLimit(schema)
                              ? labels.form.type.transactionQuantityLimit
                                  .interval.placeholder
                              : labels.form.type.transactionAmountLimit.interval
                                  .placeholder
                          "
                          :disabled="!canEdit || !schema.status"
                          :allowClear="true"
                          :valueKey="'value'"
                          :labelKey="'label'"
                          v-bind="schema.status ? { rules: 'required' } : {}"
                        />
                      </a-col>
                      <a-col :xs="24" :sm="24" :md="12">
                        <Input
                          v-if="isQuantityLimit(schema)"
                          v-model="schema.quantity"
                          type="number"
                          :vid="
                            labels.form.type.transactionQuantityLimit.quantity
                              .label
                          "
                          :label="
                            labels.form.type.transactionQuantityLimit.quantity
                              .label
                          "
                          :name="
                            labels.form.type.transactionQuantityLimit.quantity
                              .label
                          "
                          :placeholder="
                            labels.form.type.transactionQuantityLimit.quantity
                              .placeholder
                          "
                          :min="0"
                          :max="100"
                          :disabled="!canEdit || !schema.status"
                          :allowClear="true"
                          v-bind="schema.status ? { rules: 'required' } : {}"
                        />
                        <Input
                          v-else
                          v-model="schema.amount"
                          type="number"
                          :vid="
                            labels.form.type.transactionAmountLimit.amount.label
                          "
                          :label="
                            labels.form.type.transactionAmountLimit.amount.label
                          "
                          :name="
                            labels.form.type.transactionAmountLimit.amount.label
                          "
                          :placeholder="
                            labels.form.type.transactionAmountLimit.amount
                              .placeholder
                          "
                          :min="0"
                          :disabled="!canEdit || !schema.status"
                          :allowClear="true"
                          v-bind="schema.status ? { rules: 'required' } : {}"
                        />
                      </a-col>
                    </a-row>
                  </div>
                </template>
              </a-card>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
    </a-spin>

    <!-- SCHEMA TYOE MODAL -->
    <a-modal
      v-model="confirmSchemaModal"
      :title="labels.form.confirm.title"
      @cancel="cancelSchemaModal"
      @ok="handleSelectSchemaType"
      :okText="labels.form.confirm.okText"
      :cancelText="labels.form.confirm.cancelText"
      :cancelButtonProps="{ type: 'danger', ghost: true }"
    >
      <a-row type="flex" justify="start" :gutter="[24, 24]">
        <a-col :span="24">
          <SelectPagination
            v-model="selectedSchemaType"
            :data="types"
            :vid="labels.form.confirm.label"
            :label="labels.form.confirm.label"
            :name="labels.form.confirm.label"
            :placeholder="labels.form.confirm.placeholder"
            :allowClear="true"
            :valueKey="'value'"
            :labelKey="'label'"
            :customStyle="{ width: '100%' }"
          />
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import Input from "@/components/core/VeeValidateForm/Input.vue";
import SelectPagination from "@/components/core/SelectPagination/index.vue";
import SwitchComponent from "@/components/core/VeeValidateForm/Switch.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import router from "@/router";
import * as actions from "@/utils/actions";
import { mask } from "vue-the-mask";
import { adminPageTitle, hasRoles, roles } from "../../../utils/utils";
import { cloneDeep } from "lodash";

const INTERVALS = [
  {
    label:
      labels.transactionProfiles.form.type.transactionQuantityLimit.interval
        .minutes,
    value: "minutes",
  },
  {
    label:
      labels.transactionProfiles.form.type.transactionQuantityLimit.interval
        .hours,
    value: "hours",
  },
  {
    label:
      labels.transactionProfiles.form.type.transactionQuantityLimit.interval
        .days,
    value: "days",
  },
  {
    label:
      labels.transactionProfiles.form.type.transactionQuantityLimit.interval
        .weeks,
    value: "weeks",
  },
  {
    label:
      labels.transactionProfiles.form.type.transactionQuantityLimit.interval
        .months,
    value: "months",
  },
];

const TYPES = [
  {
    label: labels.transactionProfiles.form.type.transactionQuantityLimit.label,
    value: "TRANSACTION_QUANTITY_LIMIT",
  },
  {
    label: labels.transactionProfiles.form.type.transactionAmountLimit.label,
    value: "TRANSACTION_AMOUNT_LIMIT",
  },
];

const initialFormState = {
  name: "",
  status: true,
  schema: [
    {
      type: "TRANSACTION_QUANTITY_LIMIT",
      interval: cloneDeep(INTERVALS).find(
        (interval) => interval.value === "minutes"
      ),
      quantity: 1,
      status: false,
    },
  ],
};

export default {
  name: "TransactionProfileForm",
  directives: { themask: mask },
  components: {
    ValidationObserver,
    Input,
    SwitchComponent,
    SelectPagination,
  },
  metaInfo: {
    title: adminPageTitle(labels.transactionProfiles.title),
    titleTemplate: () => {
      return `${adminPageTitle()} - ${
        router.currentRoute.name.includes("view")
          ? labels.transactionProfiles.pageTitleViewing
          : labels.transactionProfiles.pageTitleCreate
      }`;
    },
  },
  data() {
    return {
      selectedSchemaType: null,
      confirmSchemaModal: false,
      labels: labels.transactionProfiles,
      loading: false,
      submitting: false,
      form: _.cloneDeep(initialFormState),
    };
  },
  async created() {
    this.resetForm();
    if (this.$route.params.id) {
      this.fetchData();
    }
  },
  computed: {
    ...mapState("transactionProfiles", ["transactionProfile"]),
    ...mapState("layout", ["screenWidth"]),
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    isEdit() {
      return !!this.$route.params?.id;
    },
    canEdit() {
      const { accessRoles } = this.getLoggedUser;
      return (
        !this.isEdit ||
        hasRoles(accessRoles, [
          roles.admin.ROLE_USER_TRANSACTION_PROFILES_UPDATE,
        ])
      );
    },
    canDelete() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [
        roles.admin.ROLE_USER_TRANSACTION_PROFILES_DELETE,
      ]);
    },
    dateFormat() {
      return "DD/MM/YYYY hh:mm:ss";
    },
    serverDateFormat() {
      return "YYYY-MM-DD HH:mm:ss";
    },
    money() {
      return {
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      };
    },
    isMobile() {
      return this.screenWidth < 768;
    },
    intervals() {
      return cloneDeep(INTERVALS);
    },
    types() {
      return cloneDeep(TYPES);
    },
  },
  methods: {
    ...mapActions("transactionProfiles", [
      "fetchTransactionProfile",
      "createTransactionProfile",
      "updateTransactionProfile",
      "deleteTransactionProfile",
      "resetForm",
    ]),
    isQuantityLimit(schema) {
      return schema.type === "TRANSACTION_QUANTITY_LIMIT";
    },
    getSchemaType(type) {
      return this.types.find((item) => item.value === type)?.label;
    },
    async fetchData() {
      try {
        this.loading = true;
        await this.fetchTransactionProfile(this.$route.params.id);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
        this.$router.push({ name: "admin.transaction-profiles" });
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(closeAfterSave = false) {
      if (this.submitting) return;
      this.submitting = true;
      let response = null;
      let editting = this.isEdit;
      try {
        const formData = _.cloneDeep(this.form);
        formData.schema = formData.schema.filter((item) => item.status);

        // INTERVAL
        formData.schema = formData.schema.map((item) => {
          if (
            ["TRANSACTION_QUANTITY_LIMIT", "TRANSACTION_AMOUNT_LIMIT"].includes(
              item.type
            )
          ) {
            item.interval = item.interval.value;
          }
          return item;
        });

        response = editting
          ? await this.updateTransactionProfile(formData)
          : await this.createTransactionProfile(formData);

        if (response) this.$message.success(response.message);
        if (closeAfterSave) {
          this.$router.push({ name: "admin.transaction-profiles" });
        } else {
          if (!editting) {
            this.$router.push({
              name: "admin.transaction-profiles.edit",
              params: { id: response.data.id },
            });
          }
        }
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
      } finally {
        this.submitting = false;
      }
    },
    handleCancel() {
      this.$router.push({ name: "admin.transaction-profiles" });
    },
    confirmDelete() {
      this.$confirm({
        title: this.labels.confirm.title,
        content: this.labels.confirm.subtitle,
        okText: this.labels.confirm.confirmButtonText,
        okType: "danger",
        icon: "close-circle",
        cancelText: this.labels.confirm.cancelButtonText,
        onOk: async () => {
          try {
            await this.deleteTransactionProfile(this.form.id);
            this.$store.commit(
              `transactionProfiles/${actions.GET_TRANSACTION_PROFILE}`,
              null
            );
            this.$message.success(this.labels.deletedSuccess);
            this.$router.push({ name: "admin.transaction-profiles" });
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                this.labels.deletedError
            );
          }
        },
      });
    },
    openConfirmModal() {
      this.selectedSchemaType = null;
      this.confirmSchemaModal = true;
    },
    cancelSchemaModal() {
      this.confirmSchemaModal = false;
      this.selectedSchemaType = null;
    },
    handleSelectSchemaType() {
      if (this.selectedSchemaType?.value) {
        this.form.schema.push({
          type: this.selectedSchemaType.value,
          interval: cloneDeep(INTERVALS).find(
            (interval) => interval.value === "minutes"
          ),
          ...(this.selectedSchemaType?.value === "TRANSACTION_QUANTITY_LIMIT"
            ? { quantity: 1 }
            : { amount: 1 }),
          status: false,
        });
        this.confirmSchemaModal = false;
        this.selectedSchemaType = null;
      } else {
        this.$message.error(this.labels.form.confirm.error);
      }
    },
  },
  watch: {
    "$route.params.id"(value, oldValue) {
      if (value && value !== oldValue) {
        this.fetchData();
      }
    },
    transactionProfile: {
      handler(transactionProfile) {
        Object.assign(
          this.form,
          transactionProfile
            ? {
                ..._.cloneDeep(transactionProfile),
                schema: !transactionProfile.schema.length
                  ? _.cloneDeep(initialFormState.schema).map((item) => {
                      const schema = _.cloneDeep(
                        transactionProfile
                      )?.schema.find((schema) => schema.type === item.type);

                      if (!schema) return _.cloneDeep(item);

                      // INTERVAL
                      if (schema?.interval) {
                        schema.interval = cloneDeep(INTERVALS).find(
                          (interval) => interval.value === schema.interval
                        );
                      }

                      return schema;
                    })
                  : _.cloneDeep(transactionProfile.schema).map((schema) => ({
                      ...schema,
                      ...(schema.interval && {
                        interval: cloneDeep(INTERVALS).find(
                          (interval) => interval.value === schema.interval
                        ),
                      }),
                    })),
              }
            : _.cloneDeep(initialFormState)
        );
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.form = _.cloneDeep(initialFormState);
    this.resetForm();
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.transaction-profiles-form {
  .form-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .form-icon {
    margin-right: 8px;
  }
  .form-button-group {
    margin-top: 16px;
  }
  .type-label {
    margin-bottom: 0;
    margin-top: 5px;
  }
  .schema-item {
    border-bottom: 1.5px dashed rgba(0, 0, 0, 0.2);
    margin-bottom: 16px;
    &:last-child {
      border-bottom: none;
    }
  }
}
.ant-input[disabled],
.ant-time-picker-input[disabled],
.ant-input-number-disabled,
.ant-select-disabled .ant-select-selection {
  background-color: color(--white) !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
</style>
